<template>
  <!-- Start donate Area -->
  <section class="donate-area relative section-gap" id="donate">
    <div class="overlay overlay-bg"></div>
    <div class="container" v-if="!hasError && !showPaymentForm && !showThanks">
      <div class="row d-flex justify-content-end">
        <div class="col-lg-12 col-sm-12 pb-80 header-text">
          <h1>DONATE NOW</h1>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 contact-left">
          <div class="single-info">
            <h2>Your contribution will allow us to legally challenge the approval of Portelli’s ODZ pool in Marsalforn valley</h2>
            <p>The Planning Authority has approved yet another obscene Jospeh Portelli development: a swimming pool in the Marsalforn valley of Gozo on ODZ land. This forms part of the monstrous apartment block project at the edge of the same valley.</p>
            <p>The approval of a swimming pool in a valley, in addition to destroying the sensitive nature of the site, sets a dangerous precedent for subsequent approvals of similar projects. The Planning Authority has opened the flood gates to the destruction of our valleys to appease Portelli.</p>
            <p><a href="https://www.facebook.com/ghawdix/" target="_blank">Għawdix</a>, <a href="https://www.wirtghawdex.org/" target="_blank">Wirt Għawdex</a>,<a href="https://dinlarthelwa.org/" target="_blank">Din l-Art Ħelwa</a>, <a href="http://faa.org.mt/" target="_blank">Flimkien għal Ambjent Aħjar (FAA)</a> and <a href="https://movimentgraffitti.org/" target="_blank">Moviment Graffitti</a> are planning legal actions against this dangerous decision of the Planning Authority. We need the financial contribution of the public to cover legal costs.</p>
            <p>Every donation, small or large, will help us fight the destruction of the environment of Malta and Gozo.</p>
            <p>We thank you!</p>
          </div>
        </div>
        <div class="col-lg-6 contact-right">
          <donation-form
              v-on:hasError="handleDonationError"
              v-on:donationCreated="onDonationCreated"
          />
          <img src="img/payment-form-bg.png" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="container" v-if="hasError">
      <errors :error-message="errorMessage"/>
    </div>
    <div class="container" v-if="showPaymentForm">
      <payment-form :details="donationDetails" v-on:paid="onPaid" v-on:paymentError="onPaymentError"/>
    </div>
    <div class="container" v-if="showThanks">
      <thanks-paid :details="paymentDetails"/>
    </div>
  </section>
  <!-- End donate Area -->
</template>

<script>
import DonationForm from "@/components/Donation/DonationForm";
import Errors from "@/components/Errors";
import PaymentForm from "@/components/Donation/PaymentForm";
import ThanksPaid from "@/components/ThanksPaid";
export default {
  name: "Donation",
  components: {ThanksPaid, PaymentForm, Errors, DonationForm},
  data() {
    return {
      hasError: false,
      showPaymentForm: false,
      showThanks: false,
      donationDetails: null,
      errorMessage: null,
      paymentDetails: null
    }
  },
  methods: {
    handleDonationError(error) {
      this.hasError = true
      this.errorMessage = error
    },
    onDonationCreated(response) {
      this.showPaymentForm = true
      this.donationDetails = response
    },
    onPaid(response) {
      this.showPaymentForm = false
      this.showThanks = true
      this.paymentDetails = response
    },
    onPaymentError(response) {
      this.hasError = true
      this.errorMessage = response
    }
  }
}
</script>

<style scoped>
h2 {
  color:#FFFFFF;
  margin-bottom: 0.8em;
}
img {
  margin-top: 1.5em;
}
p {
  font-size: 17px;
  line-height: 1.5;
}
</style>